interface Example {
  name: string;
  code: string;
  version: string;
}

const EXAMPLES: Example[] = [
  {
    name: "helloworld",
    code: `아??.????.???.???루
몰??.??.?????.?????
몰?아몰루몰??.????몰!아몰루아몰루몰???아몰루
모오올????.???????????아모오올루아????.????.??루
모올??.?????.???.???모올!!!아모올루아몰루
몰???아몰루몰!.!!.!!!아몰루아??.?????.??.?????루
모오올!!!.????모오올?아모오올루`,
    version: "1.0.4",
  },
  {
    name: "helloworld",
    code: `몰????.??????????? 모올????.????.?? 모오올몰모올
아모오올!!!!루 모오올모올 아모오올!!!!!!!루 아모오올루 아모오올루
아모오올???루 아몰루 아모올루 몰모올 아몰???????????루 아모오올???루
아몰모올??????루 아모오올루 아모오올!!!!!!!!루 아모올?루`,
    version: "1.1.2",
  },
  {
    name: "addition",
    code: `몰루?모올루?몰모올루`,
    version: "1.1.2",
  },
  {
    name: "gugudan",
    code: `모오올???.??.??????? 모오오올?????.????.???
몰?? 모올? 모오오올?
몰!!!!!!!!!!은?행가???????자!털!자
모올!!!!!!!!!!은?행가??????자!털!자
몰루 아모오올루 모올루 아모오오올루 몰.모올루 아????.????????루 모올?가????자!
몰?아??.?????루 모올???.!!! 가???자!
아몰몰???.???루 아몰???.??.???루 아몰몰!!!.????루`,
    version: "1.1.2",
  },
];

export default EXAMPLES;
